import React from 'react';

import {
  Layout,
  SEO,
  PageHero,
  GradientContainer,
  Heading,
  TextWithImage,
  TrueProtein,
  Instagram,
  ContactPageForm,
  Map,
} from '../components';
import { useGraphQL } from '../hooks';

function ServicesPage() {
  const { marcosPaulo } = useGraphQL();
  return (
    <Layout>
      <SEO title="Services" />
      <PageHero
        heading="nXtLvL Services"
        image={marcosPaulo.childImageSharp.fluid}
      />
      <GradientContainer>
        <div className="px-4 py-24 sm:px-6 lg:px-8">
          <Heading eyebrowText="Get ready to get">Energised!</Heading>
          <div className="relative grid max-w-xl py-4 mx-auto mt-12 border-2 border-white lg:max-w-5xl sm:py-12 gap-y-24">
            <FunctionalFitness />
            <PowerLifting />
            <InfraredSauna />
            <IceBath />
            <Normatec />
            <ChineseMedicine />
            <SupplementStore />
            <SportsMassage />
            <TheBar />
          </div>
        </div>
      </GradientContainer>
      <TrueProtein />
      <Instagram />
      <ContactPageForm />
      <Map />
    </Layout>
  );
}

function ChineseMedicine() {
  const { chineseMedicine } = useGraphQL();
  return (
    <TextWithImage
      heading="Chinese Medicine"
      ctaLink="/services/chinese-medicine/"
      ctaLabel="Read more"
      image={chineseMedicine.childImageSharp.fluid}
    >
      <p>
        At nXtLvL we understand that overall health and wellbeing goes far
        beyond fitness. Traditional Chinese Medicine fuses aromatic medicine
        with acupuncture, cupping and massage to work on body and mind. With
        special interest in musculoskeletal pain, women's health, pediatrics,
        auto-immune conditions and mental/emotional well-being. It is a
        multidimensional approach and Francine just wants you to be able to live
        your best life & thrive.
      </p>
    </TextWithImage>
  );
}

function FunctionalFitness() {
  const { functionalFitness } = useGraphQL();
  return (
    <TextWithImage
      eyebrowText="Smash your goals with"
      heading="Functional Fitness"
      ctaLink="/services/functional-fitness/"
      ctaLabel="Read more"
      image={functionalFitness.childImageSharp.fluid}
      reverse
    >
      <p>
        nXtLvL offers a boutique industrial-inspired functional fitness training
        area with state-of-the-art equipment, resistance training, and
        functional training spaces that allow you learn new techniques,
        challenge yourself, and reach all your training goals.
      </p>
    </TextWithImage>
  );
}

function IceBath() {
  const { iceBath } = useGraphQL();
  return (
    <TextWithImage
      heading="Ice Bath & Hydrotherapy"
      ctaLink="/services/ice-bath-hydrotherapy/"
      ctaLabel="Read more"
      image={iceBath.childImageSharp.fluid}
    >
      <p>
        nXtLvL Performance & Recovery Centre is the home of the region’s first
        recovery centre. Complete with infrared sauna, ozone filtered ice bath,
        hydrotherapy hot spa and Normatec Compression Boots.
      </p>
    </TextWithImage>
  );
}

function PowerLifting() {
  const { nxtlvlLift } = useGraphQL();
  return (
    <TextWithImage
      heading="Olympic Lifting and Power Lifting"
      ctaLink="/services/olympic-lifting/"
      ctaLabel="Read more"
      image={nxtlvlLift.childImageSharp.fluid}
    >
      <p>
        Lift heavy with proper technique at NXTLVL Performance & Recovery
        Centre’s dedicated Olympic and Power lifting area, using all the latest
        weightlifting, powerlifting, and plyometrics equipment.
      </p>
    </TextWithImage>
  );
}

function SportsMassage() {
  const { sportsMassage } = useGraphQL();
  return (
    <TextWithImage
      eyebrowText="Recover quicker with"
      heading="Sports Massage"
      ctaLink="/services/sport-massage/"
      ctaLabel="Read more"
      image={sportsMassage.childImageSharp.fluid}
      objectPosition="right"
    >
      <p>
        As part of our Performance & Recovery Centre, we understand how vital
        recovery is to maintaining fitness and function. NXTLVL offers in-house
        sports massage from qualified massage therapists to help you achieve
        more in the gym and in everyday life.
      </p>
    </TextWithImage>
  );
}

function InfraredSauna() {
  const { infraredSauna } = useGraphQL();
  return (
    <TextWithImage
      eyebrowText="Sweat it out in our"
      heading="Infrared Sauna"
      ctaLink="/services/infrared-sauna/"
      ctaLabel="Read more"
      image={infraredSauna.childImageSharp.fluid}
      reverse
    >
      <p>
        Our innovative infrared sauna is part of our recovery centre, helping
        your muscles recover from high intensity workout, improve circulation,
        and aid in relaxation.
      </p>
    </TextWithImage>
  );
}

function Normatec() {
  const { normatecBoots } = useGraphQL();
  return (
    <TextWithImage
      heading="Normatec Compression Boots"
      ctaLink="/services/compression-boots/"
      ctaLabel="Read more"
      image={normatecBoots.childImageSharp.fluid}
      reverse
    >
      <p>
        Our Normatec 3 compression boots use dynamic air compression to advance
        your wellness, recover faster, improve your training, and maximise your
        performance. Normatec’s patented precision pulse technology helps to
        increase circulation, revive muscles, and reduce swelling and has long
        been the choice of elite athletes around the globe and is now available
        here at nXtLvL for you.
      </p>
    </TextWithImage>
  );
}
function TheBar() {
  const { fuelBar } = useGraphQL();
  return (
    <TextWithImage
      heading="Protein/Fuel Bar"
      ctaLink="/services/the-bar/"
      ctaLabel="Read more"
      image={fuelBar.childImageSharp.fluid}
      reverse
    >
      <p>
        Come in and taste some of the amazing products at our protein bar. We
        make smoothies and protein shakes and sell a range of fresh juices,
        hydrogen water and recovery drinks along with coffee for your. nXtLvL
        isn’t just the place to train, it’s now also the place with your
        recovery covered.
      </p>
    </TextWithImage>
  );
}

function SupplementStore() {
  const { supplementStore } = useGraphQL();
  return (
    <TextWithImage
      heading="Supplement Store"
      ctaLink="/services/supplement-store/"
      ctaLabel="Read more"
      image={supplementStore.childImageSharp.fluid}
      reverse
    >
      <p>
        nXtLvL Performance & Recovery Centre is proud to be the new home of a
        supplement store. We have sourced some of the most scientifically
        recognized and pure ingredient based brands for all you performance and
        recovery needs. We use these products in our protein shakes and
        smoothies in store for you to purchase at our protein bar.
      </p>
    </TextWithImage>
  );
}

export default ServicesPage;
